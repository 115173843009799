import { Loading } from 'element-ui'
/* eslint-disable no-shadow */
const state = {
  drawer: false,
  snackbarShow: false,
  snackbarText: '',
  snackbarType: 'info',
  loader: null,
}

const mutations = {
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  SET_SNACKBAR_SHOW(state, payload) {
    state.snackbarShow = payload
  },
  SET_SNACKBAR_TEXT(state, payload) {
    state.snackbarText = payload
  },
  SET_SNACKBAR_TYPE(state, payload) {
    state.snackbarType = payload
  },
  SET_LOADER(state, payload) {
    state.loader = payload
  },
}

const actions = {
  setDrawer({ commit }, payload) {
    commit('SET_DRAWER', payload)
  },
  setLoader({ commit }, payload) {
    commit('SET_LOADER', payload)
  },
  showSnackbar({ commit }, { text, type = 'success', timeout = 2000 }) {
    commit('SET_SNACKBAR_TEXT', text)
    commit('SET_SNACKBAR_TYPE', type)
    commit('SET_SNACKBAR_SHOW', true)
    setTimeout(() => commit('SET_SNACKBAR_SHOW', false), timeout)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
