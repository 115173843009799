<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    :mini-variant="isMiniDrawer"
    mini-variant-width="65"
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->

    <div class="vertical-nav-header d-flex items-center ps-6 pe-5" style="height: 64px">
      <router-link :to="user?.role?.home || '/'" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/logos/rmc.png')"
          :max-height="isMiniDrawer ? '29px' : '40px'"
          :max-width="isMiniDrawer ? '29px' : '44px'"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <div class="app-title text--primary">JSAC</div>
        </v-slide-x-transition>
      </router-link>
    </div>
    <v-card flat tile elevation="0" height="calc(100vh - 112px)">
      <v-list expand shaped class="vertical-nav-menu-items pr-3">
        <nav-menu-section-title :title="isMiniDrawer ? null : 'Menu'" />
        <nav-menu-link
          v-for="menu in menus"
          :key="menu.to"
          :title="menu.title"
          :to="{ name: menu.to }"
          :icon="menu.icon"
        ></nav-menu-link>
      </v-list>
      <v-btn
        elevation="0"
        block
        style="position: absolute; bottom: 0; border-radius: 0"
        @click="$emit('update:isMiniDrawer', !isMiniDrawer)"
      >
        <v-icon color="primary">{{ isMiniDrawer ? icons.mdiChevronDoubleRight : icons.mdiChevronDoubleLeft }}</v-icon>
      </v-btn>
    </v-card>
    <!-- Navigation Items -->
  </v-navigation-drawer>
</template>

<script>
import { mdiAccountCogOutline, mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiShoppingOutline } from '@mdi/js'

import { mapState } from 'vuex'
import NavMenuLink from './components/NavMenuLink.vue'

import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'

export default {
  components: {
    NavMenuLink,
    NavMenuSectionTitle,
  },

  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    isMiniDrawer: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons: {
        mdiShoppingOutline,
        mdiAccountCogOutline,
        mdiChevronDoubleRight,
        mdiChevronDoubleLeft,
      },
    }
  },

  computed: {
    ...mapState('auth', ['user']),

    menus() {
      return this.user ? this.user.role.menus : []
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
