import Vue from 'vue'
import { mdiCircle, mdiImage, mdiVectorLine, mdiVectorRectangle } from '@mdi/js'

Vue.filter('date', function (value) {
  if (value) return new Date(value).toLocaleDateString('en-GB')
  else return null
})

Vue.filter('time', function (value) {
  if (value) return new Date(value).toLocaleTimeString('en-GB')
  else return null
})

Vue.filter('datetime', function (value) {
  if (value) return new Date(value).toLocaleDateString('en-GB') + ' ' + new Date(value).toLocaleTimeString('en-GB')
  else return null
})

Vue.filter('money', function (value) {
  if (value != null) return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
})

Vue.filter('size', function (bytes) {
  if (bytes === 0) return '0 B'
  const kb = bytes / 1024
  const mb = bytes / (1024 * 1024)
  const gb = bytes / (1024 * 1024 * 1024)
  if (kb < 1024) return kb.toFixed(0) + ' KB'
  if (mb < 1024) return mb.toFixed(1) + ' MB'
  return gb.toFixed(2) + ' GB'
})

Vue.filter('layerType', function (layer) {
  if (layer.type === 'raster') return mdiImage
  else {
    switch (layer.vectorType.toUpperCase()) {
      case 'POLYGON':
        return mdiVectorRectangle
      case 'POINT':
        return mdiCircle
      case 'LINESTRING':
        return mdiVectorLine
    }
  }
})

Vue.filter('sortName', function (fullName) {
  return fullName
    .split(' ')
    .map(w => w.charAt(0).toUpperCase())
    .join('')
})
