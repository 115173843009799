import store from '@/store'
import { getAuth } from '@/services/auth'
export default function permission(router) {
  const commonRoutes = ['error']
  const routeBlacklistAfterLoggedIn = ['login']
  router.beforeEach(async (to, _from, next) => {
    if (commonRoutes.includes(to.name)) return next()

    const isAuth = getAuth()

    if (isAuth) {
      if (to.name === 'logout') {
        await store.dispatch('auth/logout')
        return
      }

      const user = store.state.auth.user || (await store.dispatch('auth/getCurrentUser'))
      await store.dispatch('category/setCategories')

      if (routeBlacklistAfterLoggedIn.includes(to.name) || to.name === 'home') {
        return next(user.role.home)
      }

      next()
    } else {
      if (routeBlacklistAfterLoggedIn.includes(to.name)) next()
      else next({ name: 'login' })
    }

    return true
  })
}
