<template>
  <v-btn icon small :title="$vuetify.theme.dark ? 'Switch theme to Light' : 'Switch theme to Dark'" @click="setTheme">
    <v-fade-transition mode="out-in">
      <v-icon :key="$vuetify.theme.dark">
        {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
      </v-icon>
    </v-fade-transition>
  </v-btn>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
  methods: {
    setTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      if (this.$vuetify.theme.dark) localStorage.setItem('dark', '1')
      else localStorage.removeItem('dark')
    },
  },
  mounted() {
    if (localStorage.getItem('dark')) this.$vuetify.theme.dark = true
  },
}
</script>

<style></style>
