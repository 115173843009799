<template>
  <v-snackbar v-model="snackbar" :color="snackbarType" :timeout="-1" top>
    {{ snackbarText }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" dark text @click="snackbar = false">
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
  data() {
    return {
      snackbar: false,
      icons: { mdiClose },
    }
  },

  computed: {
    ...mapGetters(['snackbarShow', 'snackbarType', 'snackbarText']),
  },

  watch: {
    snackbarShow(val) {
      this.snackbar = val
    },
    snackbar(val) {
      this.$store.commit('app/SET_SNACKBAR_SHOW', val)
    },
  },
}
</script>
