import Vue from 'vue'

Vue.filter('area', area => {
  const KM_CONVERT_PARAM = 1000000

  if (area >= KM_CONVERT_PARAM) {
    return `${(area / KM_CONVERT_PARAM).toLocaleString()} km²`
  }

  return `${area.toLocaleString()} m²`
})
