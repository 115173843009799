import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import getters from './getters'
import category from './modules/category'
import upload from './modules/upload'
import map from './modules/map'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { app, auth, category, upload, map },
  getters,
})

export default store
