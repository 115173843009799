const getters = {
  drawer: state => state.app.drawer,
  snackbarShow: state => state.app.snackbarShow,
  snackbarText: state => state.app.snackbarText,
  snackbarType: state => state.app.snackbarType,
  loader: state => state.app.loader,
  email: state => state.user.email,
  name: state => state.user.fullName,
  avatar: state => state.user.avatar,
}
export default getters
