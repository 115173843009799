<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <Loader />
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: {
    Loader,
    Snackbar,
  },
}
</script>

<style></style>
