import { getDepartments, getWards, getPlots } from '@/api/category'

const state = {
  departments: [],
  wards: [],
  plots: [],
}

const mutations = {
  SET_DEPARTMENTS(state, payload) {
    state.departments = payload
  },
  SET_WARDS(state, payload) {
    state.wards = payload
  },
}

const actions = {
  async setDepartments({ commit }) {
    if (state.departments.length > 0) return
    const { data } = await getDepartments({ with: 'roles:id*name;designations:id*name' })
    commit('SET_DEPARTMENTS', data)
  },

  async setWards({ commit }) {
    if (state.wards.length > 0) return
    const [wards, plots] = (await Promise.all([getWards({ select: 'id*name', sortBy: 'id' }), getPlots()])).map(
      res => res.data,
    )
    wards.forEach(ward => {
      ward.plots = plots.filter(plot => plot.WARD_NO === ward.id).map(plot => plot.KIDE)
    })
    commit('SET_WARDS', wards)
  },

  async setCategories({ dispatch }) {
    return Promise.all([dispatch('setDepartments'), dispatch('setWards')])
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
