<template>
  <v-overlay style="z-index:9999" :value="loader">
    <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['loader']),
  },
}
</script>
