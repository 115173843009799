import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import store from '@/store'

axios.default.withCredentials = true
const service = axios.create({
  baseURL: '/api',
  withCredentials: true,
  timeout: 30000,
})

service.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
)

service.interceptors.response.use(
  response => response.data,
  error => {
    if (!error.response) return Promise.reject(error)
    if (error.response.status === 422) {
      const errorDetails = error.response.data.errors
      const firstError = errorDetails[0]
      Vue.prototype.$message(firstError.message, 'error')
    } else if (error.response.status === 401) {
      Vue.prototype.$message('You are logged out. Please login again.', 'error')
      store.dispatch('auth/removeAuth').then(() => {
        window.location = '/login'
      })
    } else if (error.response.status === 403) {
      router.push({ name: 'error', params: { code: 403 } })
    } else if (error.response.status === 404) {
      router.push({ name: 'error' })
    } else {
      Vue.prototype.$message(error.response.data.error.message, 'error')
    }
    return Promise.reject(error)
  },
)

export default service
