require('./overrides.scss')
import colors from 'vuetify/lib/util/colors'
export default {
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken1,
        accent: colors.blue.darken1,
        secondary: colors.grey.base,
        success: colors.green.base,
        info: colors.blue.base,
        warning: colors.orange.base,
        error: colors.red.base,
      },
      dark: {
        primary: colors.blue.darken3,
        accent: colors.blue.darken4,
        secondary: colors.grey.darken3,
        success: colors.green.darken3,
        info: colors.blue.darken3,
        warning: colors.orange.darken3,
        error: colors.red.darken3,
      },
    },
  },
}
