<template>
  <v-expansion-panels
    style="position: absolute; z-index: 20; bottom: 0; right: 12px; width: 350px"
    v-model="panel"
    v-show="visible"
  >
    <v-expansion-panel readonly>
      <v-expansion-panel-header disable-icon-rotate
        >Upload List
        <template v-slot:actions>
          <v-btn icon @click="setPanel" v-if="panel === null">
            <v-icon size="30"> {{ icons.mdiChevronUp }} </v-icon></v-btn
          >
          <v-btn icon @click="setPanel" v-else>
            <v-icon size="30"> {{ icons.mdiChevronDown }} </v-icon></v-btn
          >
          <v-btn icon @click="close">
            <v-icon> {{ icons.mdiClose }} </v-icon></v-btn
          >
        </template>
      </v-expansion-panel-header>
      <v-divider></v-divider>
      <v-expansion-panel-content>
        <div style="max-height: 200px; overflow-y: auto">
          <v-list dense>
            <v-list-item v-for="layer in layers" :key="layer.id">
              <v-list-item-icon>
                <v-icon>{{ layer.type === 'vector' ? icons.mdiVectorRectangle : icons.mdiImage }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <span>{{ layer.name }}</span>
                  <div class="ml-auto">
                    <div
                      style="width: 25px"
                      @mouseover="updateCancelable({ id: layer.id, value: true })"
                      @mouseleave="updateCancelable({ id: layer.id, value: false })"
                    >
                      <v-progress-circular
                        class="p-4"
                        style="width: 25px"
                        v-if="!layer.cancelable && layer.status === 'running'"
                        :rotate="90"
                        :size="25"
                        :width="2"
                        :value="layer.progress"
                        color="primary"
                      >
                        {{ layer.progress }}
                      </v-progress-circular>
                      <v-icon
                        :size="25"
                        title="Cancel"
                        v-if="layer.cancelable && layer.status === 'running'"
                        @click="cancel(layer.id)"
                        >{{ icons.mdiCancel }}</v-icon
                      >
                      <v-icon v-if="layer.status === 'success'" color="success" :size="25">{{
                        icons.mdiCheckCircleOutline
                      }}</v-icon>
                      <v-icon :title="layer.error" v-if="layer.status === 'failed'" color="error" :size="25">{{
                        icons.mdiAlertCircleOutline
                      }}</v-icon>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import {
  mdiClose,
  mdiChevronUp,
  mdiChevronDown,
  mdiVectorRectangle,
  mdiCancel,
  mdiImage,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
} from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      icons: {
        mdiClose,
        mdiChevronUp,
        mdiChevronDown,
        mdiVectorRectangle,
        mdiCancel,
        mdiImage,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
      },
      panel: 0,
      showCancelButton: false,
    }
  },
  computed: {
    ...mapState('upload', ['layers']),
    visible: {
      get() {
        return this.$store.state.upload.visible
      },
      set(value) {
        this.$store.commit('upload/SET_VISIBLE', value)
      },
    },
  },
  methods: {
    ...mapActions('upload', ['cancel', 'cancelAll']),
    ...mapMutations('upload', { updateCancelable: 'UPDATE_CANCELABLE' }),
    setPanel() {
      this.panel = this.panel === 0 ? null : 0
    },
    async close() {
      if (this.layers.some(layer => layer.status === 'running'))
        await this.$confirm('Are you sure when canceling all upload tasks?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
      this.cancelAll()
      this.visible = false
    },
  },
}
</script>
