<template>
  <v-btn
    v-if="user"
    icon
    small
    title="Feedback"
    @click="openFeedback"
  >
    <v-icon>
      {{ icons.mdiCommentTextOutline }}
    </v-icon>
  </v-btn>
</template>

<script>
import { mdiCommentTextOutline } from '@mdi/js'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      icons: {
        mdiCommentTextOutline,
      },
    }
  },

  computed: {
    ...mapState('auth', ['user']),
  },

  methods: {
    getEmailDomain(email) {
      return email.split('@')[1]
    },

    openComposeWindow(senderEmail, targetEmail) {
      const domain = this.getEmailDomain(senderEmail)

      let url = ''

      switch (domain.toLowerCase()) {
        case 'gmail.com':
          url = `https://mail.google.com/mail/?view=cm&fs=1&to=${targetEmail}`
          break
        case 'yahoo.com':
        case 'ymail.com':
        case 'rocketmail.com':
          url = `https://compose.mail.yahoo.com/?to=${targetEmail}`
          break
        case 'outlook.com':
        case 'hotmail.com':
        case 'live.com':
          url = `https://outlook.live.com/owa/?path=/mail/action/compose&to=${targetEmail}`
          break
        case 'aol.com':
          url = `https://mail.aol.com/webmail-std/en-us/compose-message?to=${targetEmail}`
          break
        case 'icloud.com':
          url = `https://www.icloud.com/#mail/compose/${targetEmail}`
          break
        case 'zoho.com':
          url = `https://mail.zoho.com/zm/#mail/compose/${targetEmail}`
          break
        case 'mail.com':
          url = `https://www.mail.com/mail/compose/?to=${targetEmail}`
          break
        default:
          // Default using gmail
          url = `https://mail.google.com/mail/?view=cm&fs=1&to=${targetEmail}`
      }

      window.open(url, '_blank')
    },

    openFeedback() {
      if (!this.user) return

      this.openComposeWindow(this.user.email, process.env.VUE_APP_FEEDBACK_SUPPORT_EMAIL)
    },
  },
}
</script>
