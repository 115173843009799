import request from '@/services/request'
export function index(params) {
  return request({
    url: '/layers',
    method: 'get',
    params,
  })
}

export function update(id, data) {
  return request({
    url: `/layers/${id}`,
    method: 'put',
    data,
  })
}

export function store(data) {
  return request({
    url: '/layers',
    method: 'post',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `/layers/${id}`,
    method: 'delete',
  })
}
export function upload(data, onUploadProgress, signal) {
  return request({
    url: `/layers/upload`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
    signal,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    timeout: 0,
  })
}
export function toggleFavorite(id, isFavorite) {
  return request({
    url: `/layers/${id}/favorite`,
    method: 'post',
    data: { isFavorite },
  })
}

export function togglePublish(id, isPublish) {
  return request({
    url: `/layers/${id}/publish`,
    method: 'post',
    data: { isPublish },
  })
}

export function getTableAttribute(id) {
  return request({
    url: `/layers/${id}/table-attribute`,
    method: 'get',
  })
}

export function identify(layerIds, coordinate, distance) {
  return request({
    url: `/layers/identify`,
    method: 'post',
    data: {
      layerIds,
      coordinate,
      distance,
    },
  })
}

export function getDefaultExtent() {
  return request({
    url: `/layers/default-extent`,
    method: 'get',
  })
}

export function getFeatureGeometry(layerId, featureId) {
  return request({
    url: `/layers/${layerId}/geometry`,
    method: 'get',
    params: { featureId },
  })
}

export function download(layerId) {
  return request({
    url: `/layers/${layerId}/download`,
    method: 'get',
    params: { featureId },
  })
}
