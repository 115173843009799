import Vue from 'vue'

Vue.filter('shortNumber', number => {
  if (number == null) return ''

  const shortNumber = number.toFixed(2)

  if (Number.isInteger(Number(shortNumber))) return shortNumber

  return shortNumber
})

Vue.filter('number', n => Number(n).toLocaleString())
