import request from '@/services/request'

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data,
  })
}

export function getGoogleRedirectLink(data) {
  return request({
    url: '/auth/google/redirect',
    method: 'get',
    data,
  })
}

export function loginWithGoogle(params) {
  return request({
    url: '/auth/google/login',
    method: 'get',
    params,
  })
}

export function register(data) {
  return request({
    url: '/auth/register',
    method: 'post',
    data,
  })
}

export function sendEmailVerification(data) {
  return request({
    url: '/auth/verification/send',
    method: 'post',
    data,
  })
}

export function forgotPassword(data) {
  return request({
    url: '/auth/password/forgot',
    method: 'post',
    data,
  })
}
export function resetPassword(data) {
  return request({
    url: '/auth/password/reset',
    method: 'post',
    data,
  })
}
export function changePassword(data) {
  return request({
    url: '/auth/password',
    method: 'put',
    data,
  })
}
export function getCurrentUser() {
  return request({
    url: '/auth/me',
    method: 'get',
  })
}

export function updateAccountInfo(data) {
  return request({
    url: '/auth/profile',
    method: 'put',
    data,
  })
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post',
  })
}
