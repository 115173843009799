import { upload } from '@/api/layer'
import axios from 'axios'
const state = {
  visible: false,
  layers: [],
}

const CHUNK_SIZE = 50 * 1024 * 1024 // 20MB

const MIME = {
  tif: 'image/tiff',
  tiff: 'image/tiff',
  zip: 'application/zip',
  geojson: 'application/vnd.geo+json',
}

const generateRandomString = length => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const uploadChunk = async (extension, chunk, totalChunks, currentChunk, formData, signal) => {
  formData.append('file', chunk)
  formData.append('totalChunks', totalChunks)
  formData.append('currentChunk', currentChunk)
  formData.append('extension', extension)
  return upload(formData, undefined, signal)
}
const mutations = {
  ADD_LAYER(state, layer) {
    state.layers.unshift(layer)
  },

  SET_VISIBLE(state, payload) {
    state.visible = payload
    this.layers = []
  },

  UPDATE_STATUS(state, { id, status, error }) {
    const layer = state.layers.find(layer => layer.id === id)
    layer.status = status
    layer.error = error
  },

  CANCEL(state, id) {
    const layer = state.layers.find(layer => layer.id === id)
    layer.status = 'cancelled'
  },

  UPDATE_CURRENT_CHUNK(state, { id, currentChunk }) {
    const layer = state.layers.find(layer => layer.id === id)
    layer.currentChunk = currentChunk
    layer.progress = Math.floor((currentChunk / layer.totalChunks) * 100)
  },

  UPDATE_CANCELABLE(state, { id, value }) {
    const layer = state.layers.find(layer => layer.id === id)
    layer.cancelable = value
  },
}
const actions = {
  async start({ commit }, { name, type, departmentId, file }) {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE)
    const layer = {
      id: +new Date(),
      name,
      status: 'running',
      progress: 0,
      type,
      abortCtrl: new AbortController(),
      error: null,
      cancelable: false,
      currentChunk: 0,
      totalChunks,
      progress: 0,
    }
    commit('ADD_LAYER', layer)
    commit('SET_VISIBLE', true)
    const extension = file.name.split('.').pop()
    let startByte = 0
    const fileName = generateRandomString(20)
    for (let i = 1; i <= totalChunks; i++) {
      const endByte = Math.min(startByte + CHUNK_SIZE, file.size)
      const chunk = new File([file.slice(startByte, endByte)], fileName + '.' + extension, {
        type: MIME[extension],
        lastModified: Date.now(),
      })

      const formData = new FormData()
      formData.append('name', name)
      formData.append('departmentId', departmentId || '')
      try {
        await uploadChunk(extension, chunk, totalChunks, i, formData, layer.abortCtrl.signal)
        startByte = endByte
        commit('UPDATE_CURRENT_CHUNK', { id: layer.id, currentChunk: i })
      } catch (error) {
        if (axios.isCancel(error)) {
          return commit('UPDATE_STATUS', { id: layer.id, status: 'failed', error: 'Canceled by user' })
        } else {
          console.log(error)
          return commit('UPDATE_STATUS', { id: layer.id, status: 'failed', error: 'Failed to upload layer' })
        }
      }
    }
    commit('UPDATE_STATUS', { id: layer.id, status: 'success' })
  },

  cancel({ state }, id) {
    const layer = state.layers.find(layer => layer.id === id)
    layer.abortCtrl.abort()
  },

  cancelAll({ dispatch }) {
    state.layers.forEach(layer => {
      if (layer.status === 'running') dispatch('cancel', layer.id)
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
