import NProgress from '@/services/nprogress'
export default function progress(router) {
  router.beforeEach((_, __, next) => {
    NProgress.start()
    next()
  })
  router.afterEach(() => {
    NProgress.done()
  })
}
