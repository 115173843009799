import request from '@/services/request'
export function getDepartments(params) {
  return request({
    url: '/departments',
    method: 'get',
    params,
  })
}
export function getWards(params) {
  return request({
    url: '/wards',
    method: 'get',
    params,
  })
}
export function getCdStatuses() {
  return request({
    url: '/cd-statuses',
    method: 'get',
  })
}
export function getCdTypes() {
  return request({
    url: '/cd-types',
    method: 'get',
  })
}
export function getCdClassificationTypes() {
  return request({
    url: '/cd-classification-types',
    method: 'get',
  })
}
export function getComplaintStatuses() {
  return request({
    url: '/complaint-statuses',
    method: 'get',
  })
}
export function getPlots() {
  return request({
    url: '/plots',
    method: 'get',
    params: {
      minimal: true,
    },
  })
}
