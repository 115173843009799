import getPageTitle from '@/services/page'
export default function pageTitle(router) {
  router.beforeEach((to, _from, next) => {
    document.title = getPageTitle(
      to.name
        ?.split('-')
        .map(w => w.charAt(0).toUpperCase() + w.slice(1))
        .join(' '),
    )
    next()
  })
}
