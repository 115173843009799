import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: true })
const style = document.createElement('style')
style.textContent = `
#nprogress .bar {
  background: var(--v-primary-base); !important;
}
#nprogress .spinner .spinner-icon {
  border-top-color: var(--v-primary-base); !important;
  border-left-color: var(--v-primary-base); !important;
}
`
document.body.appendChild(style)
export default NProgress
