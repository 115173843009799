import Vue from 'vue'
import VueRouter from 'vue-router'
import progress from './middlewares/progress'
import pageTitle from './middlewares/page-title'
import permission from './middlewares/permission'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/pages/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/map-view',
    name: 'map-view',
    component: () => import('@/views/map-view/index.vue'),
    meta: {
      roles: ['admin', 'official'],
      keepAlive: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      keepAlive: true,
      roles: ['admin', 'official'],
    },
  },
  {
    path: '/mis',
    name: 'MIS',
    component: () => import('@/views/mis/index.vue'),
    meta: {
      keepAlive: true,
      roles: ['admin', 'official'],
    },
  },
  {
    path: '/layer-management',
    name: 'layer-management',
    component: () => import('@/views/layer-management/index.vue'),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/task-management',
    name: 'task-management',
    component: () => import('@/views/task-management/index.vue'),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: () => import('@/views/user-management/index.vue'),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/log-management',
    name: 'log-management',
    component: () => import('@/views/log-management/index.vue'),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/index.vue'),
    meta: {
      roles: ['admin', 'official'],
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/views/faq/index.vue'),
    meta: {
      roles: ['admin', 'official'],
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/help/index.vue'),
    meta: {
      roles: ['admin', 'official'],
    },
  },
  {
    path: '/',
    name: 'home',
  },
  {
    path: '/logout',
    name: 'logout',
  },

  {
    path: '*', // Matches any unmatched path
    redirect: 'error',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

pageTitle(router)
progress(router)
permission(router)

export default router
