<template>
  <component :is="resolveLayout">
    <keep-alive>
      <router-view :key="$route.name" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view :key="$route.name" v-if="!$route.meta.keepAlive"></router-view>
  </component>
</template>

<script>
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  computed: {
    resolveLayout() {
      if (this.$route.path === null) return null
      if (this.$route.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    },
  },
}
</script>
