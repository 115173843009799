<template>
  <v-app>
    <vertical-nav-menu :isMiniDrawer.sync="isMiniDrawer" :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-5">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>

          <div
            v-if="$vuetify.breakpoint.width > 800"
            class="text-center"
            :style="{
              color: '#1679ab',
              marginLeft: `calc(100vw / 2 - 267px - ${isMiniDrawer ? '85' : '280'}px)`,
            }"
          >
            <h3>CIVIC LAND AREA INFRASTRUCTURE MANAGEMENT SYSTEM</h3>
          </div>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <div class="d-flex align-center flex-gap-3">
            <theme-switcher></theme-switcher>
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="px-3">
        <v-card flat tile height="calc(100vh - 112px)" style="overflow-y: auto">
          <slot></slot>
        </v-card>
      </div>

      <Loader />
      <Snackbar />
      <portal-target name="overlay" />
    </v-main>

    <v-footer app color="transparent" height="37" absolute class="px-0 py-0">
      <v-card
        flat
        tile
        width="100%"
        class="font-weight-bold text-center fill-height d-flex justify-center flex-column"
        :style="{ fontSize: $vuetify.breakpoint.width > 600 ? '0.875rem' : '0.65rem' }"
      >
        <span>
          &copy;
          {{ new Date().getFullYear() }}
          CONTENTS OWNED AND MANAGED BY JSAC, GOVERNMENT OF JHARKHAND
        </span>
      </v-card>
    </v-footer>
    <UploadList />
  </v-app>
</template>

<script>
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import FeedbackButton from '@/components/FeedbackButton.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import Snackbar from '@/components/Snackbar.vue'
import Loader from '@/components/Loader.vue'
import UploadList from '@/components/UploadList.vue'
export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    Snackbar,
    Loader,
    FeedbackButton,
    UploadList,
  },

  data() {
    return {
      isDrawerOpen: null,
      isMiniDrawer: true,
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  margin-left: auto;
  margin-right: auto;
}
</style>
