<template>
  <v-menu
    v-model="menuVisible"
    offset-y
    min-width="290px"
    transition="scale-transition"
    :nudge-right="40"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="{ ...$attrs, ...attrs }"
        :value="dateRangeText"
        :class="textFieldClass"
        readonly
        clearable
        v-on="on"
        :hide-details="hideDetails"
        @click:clear="onClear"
      />
    </template>

    <v-date-picker v-model="dateRange" range :min="min" :max="max" @change="submit" />
  </v-menu>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    value: {
      type: Array,
      default: undefined,
    },

    displayFormat: {
      type: String,
      default: undefined,
    },

    min: {
      type: String,
      default: undefined,
    },

    max: {
      type: String,
      default: undefined,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    textFieldClass: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      menuVisible: false,

      dateRange: undefined,

      dateRangeText: '',
    }
  },

  watch: {
    menuVisible(val) {
      if (val) this.initPickerData()
    },

    value() {
      this.initDateRangeText()
    },
  },

  created() {
    this.initDateRangeText()
  },

  methods: {
    initDateRangeText() {
      if (!this.value) {
        this.onClear()

        return
      }

      const startText = this.composeDateText(this.value[0], this.displayFormat)

      const endText = this.composeDateText(this.value[1], this.displayFormat)

      this.dateRangeText = `${startText} — ${endText}`
    },

    composeDateText(date, formatString) {
      if (!date) return ''

      if (!formatString) {
        return new Date(date).toLocaleDateString(navigator.language)
      }

      return format(new Date(date), formatString)
    },

    initPickerData() {
      if (!this.value) {
        this.dateRange = undefined

        return
      }

      const startDate = this.composeDateData(this.value[0])
      const endDate = this.composeDateData(this.value[1])

      this.dateRange = [startDate, endDate]
    },

    composeDateData(val) {
      const date = !val ? undefined : new Date(val)

      return format(date, 'yyyy-MM-dd')
    },

    onClear() {
      this.dateRangeText = undefined
      this.dateRange = undefined

      this.$emit('input', undefined)
    },

    cancel() {
      this.menuVisible = false
    },

    submit() {
      this.dateRange.sort()
      const startDate = new Date(`${this.dateRange[0]} 00:00:00`)
      const endDate = new Date(`${this.dateRange[1]} 23:59:59`)

      const output = [startDate.toISOString(), endDate.toISOString()]

      this.$emit('input', output)
      this.menuVisible = false
    },
  },
}
</script>

<style>
.date-picker-container .v-picker__title.v-picker__title--landscape {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
</style>
