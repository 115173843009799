/* eslint-disable no-shadow */
import { login, logout, getCurrentUser } from '@/api/auth'
import router from '@/router'
import { getAuth, setAuth } from '@/services/auth'
import roles from '@/constants/roles'

const state = {
  auth: getAuth(),
  user: null,
}

const mutations = {
  SET_AUTH: (state, payload) => {
    state.auth = payload
    setAuth(payload)
  },
  SET_USER: (state, user) => {
    state.user = user
  },
}

const actions = {
  async login({ commit }, { email, password, rememberMe }) {
    await login({ email: email.trim(), password, rememberMe })
    commit('SET_AUTH', true)
    window.location.href = '/'
  },

  getCurrentUser({ commit }) {
    return new Promise((resolve, reject) => {
      getCurrentUser()
        .then(response => {
          const { data } = response

          if (!data) {
            return reject(new Error('Verification failed, please login again.'))
          }

          const role = roles.find(r => r.id === data.roleId)

          commit('SET_USER', { ...data, role })

          return resolve(state.user)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          window.location.replace('/login')
          commit('SET_AUTH', false)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeAuth({ commit }) {
    return new Promise(resolve => {
      commit('SET_AUTH', false)
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
