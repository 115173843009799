import {
  mdiMapOutline,
  mdiAccountDetailsOutline,
  mdiHistory,
  mdiAccount,
  mdiLayersOutline,
  mdiFrequentlyAskedQuestions,
  mdiFileImportOutline,
  mdiMonitorDashboard,
  mdiFaceAgent,
  mdiInformationOutline,
} from '@mdi/js'

export const roles = [
  {
    id: 1,
    code: 'admin',
    text: 'Admin',
    home: 'dashboard',
    menus: [
      {
        title: 'Dashboard',
        icon: mdiMonitorDashboard,
        to: 'dashboard',
      },
      {
        title: 'Map View',
        icon: mdiMapOutline,
        to: 'map-view',
      },
      {
        title: 'MIS',
        icon: mdiInformationOutline,
        to: 'MIS',
      },
      {
        title: 'Layer Management',
        icon: mdiLayersOutline,
        to: 'layer-management',
      },
      {
        title: 'Task Management',
        icon: mdiFileImportOutline,
        to: 'task-management',
      },
      {
        title: 'User Management',
        icon: mdiAccountDetailsOutline,
        to: 'user-management',
      },
      {
        title: 'Log Management',
        icon: mdiHistory,
        to: 'log-management',
      },
      {
        title: 'Profile',
        icon: mdiAccount,
        to: 'profile',
      },
      {
        title: 'FAQ',
        icon: mdiFrequentlyAskedQuestions,
        to: 'FAQ',
      },
      {
        title: 'Help & Support',
        icon: mdiFaceAgent,
        to: 'help',
      },
    ],
  },
  {
    id: 2,
    code: 'field_officer',
    text: 'Field Officer',
    home: 'dashboard',
    menus: [
      {
        title: 'Dashboard',
        icon: mdiMonitorDashboard,
        to: 'dashboard',
      },
      {
        title: 'Map View',
        icon: mdiMapOutline,
        to: 'map-view',
      },
      {
        title: 'MIS',
        icon: mdiInformationOutline,
        to: 'MIS',
      },
      {
        title: 'Profile',
        icon: mdiAccount,
        to: 'profile',
      },
      {
        title: 'FAQ',
        icon: mdiFrequentlyAskedQuestions,
        to: 'FAQ',
      },
      {
        title: 'Help & Support',
        icon: mdiFaceAgent,
        to: 'help',
      },
    ],
  },
  {
    id: 3,
    code: 'official',
    text: 'Official',
    home: 'dashboard',
    menus: [
      {
        title: 'Dashboard',
        icon: mdiMonitorDashboard,
        to: 'dashboard',
      },
      {
        title: 'Map View',
        icon: mdiMapOutline,
        to: 'map-view',
      },
      {
        title: 'MIS',
        icon: mdiInformationOutline,
        to: 'MIS',
      },
      {
        title: 'Profile',
        icon: mdiAccount,
        to: 'profile',
      },
      {
        title: 'FAQ',
        icon: mdiFrequentlyAskedQuestions,
        to: 'FAQ',
      },
      {
        title: 'Help & Support',
        icon: mdiFaceAgent,
        to: 'help',
      },
    ],
  },
  {
    id: 4,
    code: 'citizen',
    text: 'Citizen',
  },
]

export default roles
